import React from 'react'
import dynamic from 'next/dynamic'

const HeroHome = dynamic(() => import('../components').then(mod => mod.HeroHome))
const MediaText = dynamic(() => import('../components').then(mod => mod.MediaText))
const SliderText = dynamic(() => import('../components').then(mod => mod.SliderText))
const MediaGridText = dynamic(() => import('../components').then(mod => mod.MediaGridText))
const MainContainer = dynamic(() => import('../components').then(mod => mod.MainContainer))
const MediaGridImages = dynamic(() => import('../components').then(mod => mod.MediaGridImages))
const HeroSlider2 = dynamic(() => import('../components').then(mod => mod.HeroSlider2))

import { isObjectEmpty } from '../utils'
import { fetchPageData } from '../services'
import { STATIC_PAGE_WP_IDS } from '../utils/siteConfig'
import getGlobalData from '../services/cacheGlobalData'

/**
 * Fixed Page - Home
 */
const Home = ({ pageData }) => {
  const header = pageData?.dovetailPageHome?.header
  const weBuildContent  = pageData?.dovetailPageHome?.weBuildContent
  const aboutUsContent  = pageData?.dovetailPageHome?.aboutUsContent
  const testimonialsContent = pageData?.dovetailPageHome?.testimonialsContent
  
  let sliderItems = []
  if (header.slider.length) {
    header.slider.forEach((item) => {
      const card = item?.showInfobox
        ? {
            title: item?.title,
            date: item?.date,
            description: item?.description,
          }
        : false

      const content = {
        project: item?.showCta ? item?.link?.label : false,
        projectLabel: "Project:",
        status: item.status,
        statusLabel: "Status:",
        src: item.image.sourceUrl,
        alt: item.image.altText,
        srcMobile: item?.imageMobile?.sourceUrl,
        altMobile: item?.imageMobile?.altText,        
        href: item.link.type == 'external' ? item.link.externalUrl : (item.link.url ? item.link.url : '#'),
        target: item.link.target,
        card: card,
        videoUrl: item.videoUrl,
        video: item.video        
      }
      sliderItems.push(content)
    })
  }

  return (
    <MainContainer>          
      <HeroSlider2 sliderItems={sliderItems} />
      
      {/* SECTION B - We build - 2x1 */}
      <MediaText
        image={weBuildContent?.image}
        heading={weBuildContent?.title}
        subHeading={weBuildContent?.headline}
        description={weBuildContent?.description}
        cta={weBuildContent?.link}
        imagePosition='left'
        aspectRatio='aspect-w-3 aspect-h-5 md:aspect-w-7 md:aspect-h-12'
        gridContainerClassName={'md:pt-0 md:pb-0'}
        isHomePage={true}
        video={weBuildContent?.video}
        hasVideoPlayback={!!weBuildContent?.video}
      />

      {/* SECTION B - About Us - 2x1 */}
      <MediaText
        image={aboutUsContent?.image}
        heading={aboutUsContent?.title}
        subHeading={aboutUsContent?.headline}
        description={aboutUsContent?.description}
        cta={aboutUsContent?.link}
        imagePosition='right'
        aspectRatio='aspect-w-4 aspect-h-5 sm:aspect-w-580 sm:aspect-h-540'
        hasVideoPlayback={false}
        sectionContainerClassName={'bg-transparent sm:bg-transparent'}
      />

      <MediaGridImages
        testimonials={true}
        heading={testimonialsContent?.title}
        subHeading={testimonialsContent?.headline}
        description={'&nbsp;'}
        projects={testimonialsContent?.items.map((item) => {          
          return {
            "testimonial": {
              "quote": item?.testimonial,
              "name": item?.name
            },
            "SingleProjectFields": {
              "headerImage": item?.image 
            }
          }
        })}
      />
    </MainContainer>
  )
}

// export default Home;
export default React.memo(Home)

export async function getStaticProps({ preview = false }) {
  const id = STATIC_PAGE_WP_IDS?.home
  const idType = 'DATABASE_ID'

  const data = await fetchPageData(id, idType, preview)

  const globalData = await getGlobalData()
  const pageData = data?.pageData || {}

  return isObjectEmpty(pageData)
    ? { notFound: true } // returns custom 404 page
    : { props: { preview, pageData, globalData } }
}
